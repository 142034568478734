<app-header-master></app-header-master>

<main class="wrapper">
	<h1>Criação de Vouchers</h1>
	<div class="spacer"></div>
	<input type="file" (change)="onFileSelected($event)" accept=".csv" />
	<div class="spacer"></div>
	<div *ngIf="hasErrors">Por gentiliza, sanar os erros da planilha.</div>
	<button
		*ngIf="!hasErrors && vouchers.length > 0 && status === 'idle'"
		class="button button__primary"
		style="width: 120px; height: 35px"
		(click)="importVouchers()"
	>
		Enviar dados
	</button>
	<div class="spacer"></div>
	<app-loading *ngIf="status === 'pending'" class="loading" slug="001"></app-loading>
	<div *ngIf="status === 'resolved'">
		<div *ngIf="results.failureVouchers.length > 0">
			<h1 [ngStyle]="{color: 'red', fontSize: '20px'}">Falha</h1>
			<!-- {{results.failureVouchers | json}} -->
			<table>
				<thead>
					<tr>
						<th>Nome</th>
						<th>Email</th>
						<th>Restaurante</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let failure of results.failureVouchers">
						<th>{{failure.name}}</th>
						<th>{{failure.email}}</th>
						<th>{{failure.venueName}}</th>
					</tr>
				</tbody>
			</table>
		</div>
		<div [ngStyle]="{height: '50px'}"></div>
		<div *ngIf="results.successVouchers.length > 0">
			<h1 [ngStyle]="{color: 'green', fontSize: '20px'}">Sucesso</h1>
			<table>
				<thead>
					<tr>
						<th>Nome</th>
						<th>Vouchers</th>
						<th>Email Enviado</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let success of results.successVouchers">
						<th>{{success.name}}</th>
						<th>
							<span *ngFor="let voucher of success.vouchers">
								{{voucher.code}} - {{voucher.venueName}}
							</span>
						</th>
						<th [ngStyle]="success.emailSent ? {color: 'green'}: {color: 'red'}">
							{{success.emailSent ? 'SIM' : 'NÃO'}}
						</th>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
	<ng-container *ngIf="status === 'rejected'">
		<h1 [ngStyle]="{color: 'red', fontSize: '20px'}">Erro</h1>
		{{error}}
	</ng-container>
</main>
<table *ngIf="status == 'idle'">
	<thead>
		<tr>
			<th>NOME</th>
			<th>EMAIL</th>
			<th>TELEFONE</th>
			<th>ID_RESTAURANTE</th>
			<th>NOME_RESTAURANTE</th>
			<th>VALOR</th>
			<th>QUANTIDADE</th>
			<th>CCO_EMAILS</th>
			<th>TIPO</th>
			<th *ngIf="hasErrors">Errors</th>
		</tr>
	</thead>
	<tbody>
		<tr *ngFor="let csvLine of vouchers">
			<td [title]="csvLine.name">{{ csvLine.name }}</td>
			<td [title]="csvLine.email">{{ csvLine.email }}</td>
			<td [title]="csvLine.phone">{{ csvLine.phone }}</td>
			<td [title]="csvLine.venueId">{{ csvLine.venueId }}</td>
			<td [title]="csvLine.venueName">{{ csvLine.venueName }}</td>
			<td [title]="csvLine.cpf">{{ csvLine.cpf }}</td>
			<td [title]="csvLine.quantity">{{ csvLine.quantity }}</td>
			<td [title]="csvLine.bccEmails">{{ csvLine.bccEmails }}</td>
			<td [title]="csvLine.type">{{ csvLine.type }}</td>
			<td *ngIf="csvLine.errors">
				<div style="color: red; font-weight: bold" *ngFor="let error of csvLine.errors">
					{{error.message}}
				</div>
			</td>
		</tr>
	</tbody>
</table>
